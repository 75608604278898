<template>
    <section class="entregas-curso  px-2 pt-4">
        <div class="row mx-0 a-center">
            <div class="col px-0 br-12 bg-progress" style="height:12px;">
                <div class="bg-gr-general h-100 br-12" :style="`width:${avance}%`" />
            </div>
            <div class="col-auto text-gris f-16">
                Avance {{ avance }} %
            </div>
        </div>
        <div class="row mx-0 mt-3 j-center">
            <div v-for="(entrega, e) in entregas" :key="e" class="tres-puntos px-1 card-entregas mx-3 my-2 border br-12 pt-2 cr-pointer" :class="entrega.hecha ? 'card-entregas' : 'card-entregas_final'" @click="entregarMaterial(entrega)">
                <template v-if="entrega.hecha">
                    <div class="row mx-0">
                        <div class="col">
                            <p class=" text-gris f-600 tres-puntos">
                                {{ entrega.titulo }}
                            </p>
                        </div>

                        <div class="col-auto px-0">
                            <el-tooltip v-if="entrega.hecha.editable == 0" placement="bottom" content="Entregado" effect="light">
                                <div>
                                    <i class="icon-checkbox-marked-circle-outline f-16 text-green" />
                                </div>
                            </el-tooltip>
                            <el-tooltip v-else placement="bottom" content="Editar la entrega" effect="light">
                                <div>
                                    <i class="icon-pencil f-16 text-green" />
                                </div>
                            </el-tooltip>
                        </div>
                    </div>
                    <div class="row mx-0 mt-4">
                        <div class="col-auto">
                            <i class="icon-email-send-outline f-22 text-general" />
                        </div>
                        <div class="col px-0">
                            <p v-text="formatearFecha(entrega.hecha.user_fecha, 'DD/ MMM /YYYY')" />
                            <p v-text="formatearFecha(entrega.hecha.user_fecha, 'hh:mm A')" />
                        </div>
                        <div class="col-auto">
                            <el-tooltip placement="bottom" :content="entrega.hecha.califica_nota ? 'Nota' : 'Sin nota'" effect="light">
                                <div>
                                    <i class="icon-format-list-checks text-general f-22" /> 
                                    {{ entrega.hecha.califica_nota ? entrega.hecha.califica_nota : '-' }}
                                </div>
                            </el-tooltip>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <p class="f-600 px-2" v-text="entrega.titulo" />
                    <div class="h-80 d-middle j-center f-22">
                        <i class="icon-cloud-upload" style="font-size:60px;" />
                        Subir Entrega
                    </div> 
                </template>
            </div>
        </div>
        <!-- Partials -->
        <modal-entregas-curso ref="descripcionEntrega" :nombre-entrega="titulo_entrega" />
        <modal-entrega-final ref="crearEntrega" @enviar-entrega="refrescarDatos" />
        <modal-editar-entrega ref="editarEntrega" @enviar-entrega="refrescarDatos" />
    </section>
</template>

<script>
import Curso from "../../../services/cursos/ver_cursos";
import { mapGetters } from 'vuex';

export default {
    components: {
        modalEntregasCurso: () => import('../partials/modalEntregasCurso'),
        modalEditarEntrega: () => import('../partials/modalEditarEntrega'),
        modalEntregaFinal: () => import('../partials/modalEntregaFinal')
    },
    data(){
        return {
            entregas: [],
            titulo_entrega: ''
        }
    },
    computed: {
        ...mapGetters({
            avance: 'cursos/ver_cursos/avance'
        }),
        idCurso(){
            return this.$route.params.id_curso
        },
    },
    mounted(){
        this.getEntregas()
    },
    methods: {
        entregarMaterial(row){
            if(row.hecha){
                if (row.hecha.editable){
                    this.$refs.editarEntrega.toggle(row);
                } else {
                    this.$refs.descripcionEntrega.toggle(row);
                }
            }else {
                this.$refs.crearEntrega.toggle(row);
            }
            this.titulo_entrega = row.titulo
        },
        refrescarDatos(data){
            this.curso = data.curso
            let entrega = this.entregas.find(e => e.id == data.entregaHecha.id_entrega)
            entrega.hecha = data.entregaHecha
            this.$store.dispatch('cursos/ver_cursos/actionObtenerAvanceUsuario', this.idCurso)
        },
        async getEntregas(){
            try {
                const { data: { data } } = await Curso.getEntregas(this.idCurso)
                this.entregas = data.entregas

            } catch (error){
                this.error_catch(error)
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.card-entregas{ min-height: 181px;width: 400px; }
.card-entregas_final{ 
    width: 400px;
    min-height: 181px;
    background: #F8F8F8;
    i{ color: #DFDFDF ; }
}
</style>